import { Obtener } from "@/Generico/Conexion";
import { axiosKtb } from "@/plugins/axios";

export class ProduccionMesaResponse 
{
    cantidad: number=0;
    volumen: number =0;
    mesa: string="";
}




export async function ObtenerPalletId(plantaId:number)
{
    return Obtener<ProduccionMesaResponse>(`produccion/obtenerpalletResumen/${plantaId}`,axiosKtb);
}